import qualitySvg from "../../assets/svg/quality.svg";
import { getRank } from '../rank'
let rank = getRank('quality')
export default {
  path: "/quality",
  redirect: "/quality/index",
  meta: {
    icon: qualitySvg,
    title: "质检管理",
    rank,
    roles: ['production_router']
  },
  children: [
    {
      path: "/quality/index",
      name: "quality",
      component: () => import("@/views/quality/index.vue"),
      meta: {
        title: "质检管理",
        keepAlive: false,
        roles:['quality_control_view','quality_control']
      }
    },

  ]
} as RouteConfigsTable;
