<template>
  <!-- <div :style="{ height: contentHeight + 'px' }" ref="scorll" :class=" props.isHover ? 'hoverScoll' : 'overflow-auto'"> -->
  <div :style="{ height: contentHeight + 'px' }" class="overflow-auto" ref="scorll" :class="{ hoverScoll: props.isHover, bgFa: bgFa }">
    <div class="inner" :style="{ 'padding-right': props.pdr + 'px' }">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, getCurrentInstance } from "vue";
import { message } from "@/utils/message";
import { isEmpty } from "lodash-es";
import { nextTick } from "process";
import { number } from "echarts";
const props = defineProps({
  from: {
    type: String,
    default: "",
  },
  surHeight: {
    //减去的高度
    type: Number,
    default: 0,
  },
  pdr: {
    //滚动条距离元素左边距离
    type: Number,
    default: 0,
  },
  isHover: {
    //鼠标放上去才显示滚动条
    type: Boolean,
    default: false,
  },
  bgFa: {
    //背景色是否是fa
    type: Boolean,
    default: false,
  },
});
const scorll = ref();
let contentHeight = ref(500);
onMounted(() => {
  nextTick(() => {
    if (scorll.value) {
      let idClientRect = scorll.value.getBoundingClientRect();
      contentHeight.value = document.body.clientHeight - idClientRect.top - props.surHeight;
    }
  });
});
</script>
<style scoped>
.hoverScoll {
  overflow: auto;
}
.hoverScoll::-webkit-scrollbar-thumb {
  background-color: #fff !important;
}
.hoverScoll:hover::-webkit-scrollbar-thumb {
  background-color: #a8a8a8 !important;
}
.bgFa::-webkit-scrollbar-thumb {
  background-color: #fafafa !important;
}
.bgFa::-webkit-scrollbar-track {
  background-color: #fafafa !important;
}
</style>
