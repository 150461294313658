import { $t } from "@/plugins/i18n";
import appStore from "@/assets/svg/appstroe.svg";
const Layout = () => import("@/layout/index.vue");
import { getRank } from '../rank'
let rank = getRank('appStore')
export default {
  path: "/appStore",
  meta: {
    icon: appStore,
    title: "应用商品",
    rank
  },
  children: [
    {
      path: "/appStore/index",
      name: "AppStore",
      component: () => import("@/views/appStore/index.vue"),
      meta: {
        title: "应用商店",
        keepAlive: false,
      }
    },


  ]
};
