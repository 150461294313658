import recordDetail from "../../assets/svg/recordDetail.svg";
import { getRank } from '../rank'
let rank = getRank('recordDetail')
export default {
  path: "/recordDetail",
  redirect: "/recordDetail/index",
  meta: {
    icon: recordDetail,
    title: "计件明细",
    rank,
    roles: ['production_router']
  },
  children: [
    {
      path: "/recordDetail/index",
      name: "recordDetail",
      component: () => import("@/views/recordDetail/index.vue"),
      meta: {
        title: "计件明细",
        keepAlive: false,
        roles: ["salary"]
      }
    },
    {
      path: "/recordDetail/userDetail",
      name: "userDetail",
      component: () => import("@/views/recordDetail/userDetail.vue"),
      meta: {
        title: "查看明细",
        keepAlive: false,
        showLink: false,
        roles: ["salary"]
      }
    },
    {
      path: "/recordDetail/checkHistory",
      name: "checkHistory",
      component: () => import("@/views//recordDetail/checkHistory.vue"),
      meta: {
        title: "历史核对",
        keepAlive: false,
        showLink: false,
        roles: ["salary"]
      }
    },
    {
      path: "/recordDetail/checkHistoryDetail",
      name: "checkHistoryDetail",
      component: () => import("@/views//recordDetail/checkHistoryDetail.vue"),
      meta: {
        title: "历史核对明细",
        keepAlive: false,
        showLink: false,
        roles: ["salary"]
      }
    },
  ]
} as RouteConfigsTable;
