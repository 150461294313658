const routerItem = [
  { name: '工作台', rank: null, id: 'workbench' },
  { name: '开发设计', rank: null, id: 'developDesign' },
  { name: '进度看板', rank: null, id: 'workflow' },
  { name: '生产管理', rank: null, id: 'order' },
  { name: '计件明细', rank: null, id: 'recordDetail' },
  { name: '采购管理', rank: null, id: 'purchaseManager' },
  { name: '成品进销存', rank: null, id: 'productPurchase' },
  { name: '布料进销存', rank: null, id: 'clothPurchase' },
  { name: '辅料进销存', rank: null, id: 'accessoryPurchase' },
  { name: '考勤', rank: null, id: 'attendance' },
  { name: '协同管理', rank: null, id: 'syncManage' },
  { name: '财务中心', rank: null, id: 'finance' },
  { name: '员工管理', rank: null, id: 'employee' },
  { name: '工序管理', rank: null, id: 'step' },
  { name: '样衣管理', rank: null, id: 'sampleDress' },
  { name: '质检管理', rank: null, id: 'quality' },
  { name: '仓库', rank: null, id: 'warehouse' },
  { name: '基础资料', rank: null, id: 'base' },
  { name: '鲲贴', rank: null, id: 'kunTie' },
  { name: '设置管理', rank: null, id: 'setting' },
  { name: '应用商品', rank: null, id: 'appStore' },
  { name: '日志管理', rank: null, id: 'log' },
  { name: 'VIP', rank: null, id: 'vip' }
]
function routerItemRank() {
  return routerItem.map((item, index) => {
    item.rank = index
    return item;
  })
}

function getRank(id) {
  let router = routerItemRank()
  let rank
  let obj = router.find(o => { return id == o.id });
  if (obj) {
    rank = obj.rank
  }
  return rank
}

export { getRank }
