import financeSvg from "../../assets/svg/finance.svg";
import { getRank } from '../rank'
let rank = getRank('finance')
export default {
  path: "/finance",
  redirect: "/finance/index",
  meta: {
    icon: financeSvg,
    title: "财务中心",
    rank,
    roles: ['production_router']

  },
  children: [
    // {
    //   path: "/finance/index",
    //   name: "Finance",
    //   component: () => import("@/views/finance/index.vue"),
    //   meta: {
    //     title: "财务中心",
    //     keepAlive: true
    //   }
    // },
    {
      path: "/finance/employeeSalary",
      name: "EmployeeSalary",
      component: () => import("@/views/finance/employeeSalary.vue"),
      meta: {
        title: "工资明细",
        keepAlive: false,
        roles:['finance_salary_view']
      }
    },
    {
      path: "/finance/inAndOut",
      name: "InAndOut",
      component: () => import("@/views/finance/inAndOut/index.vue"),
      meta: {
        title: "应收应付汇总",
        keepAlive: false,
        roles:['finance_bill_payable_view']
      }
    },
    {
      path: "/finance/receivable",
      name: "Receivable",
      component: () => import("@/views/finance/receivable/index.vue"),
      meta: {
        title: "应收账款",
        keepAlive: false,
        roles:['finance_bill_receivable_view']
      }
    },
    {
      path: "/finance/payable",
      name: "Payable",
      component: () => import("@/views/finance/payable/index.vue"),
      meta: {
        title: "应付账款",
        keepAlive: false,
        roles:['finance_bill_payable_view']
      }
    },
    {
      path: "/finance/salaryRule",
      name: "salaryRule",
      component: () => import("@/views/finance/salaryRule/index.vue"),
      meta: {
        title: "计薪规则",
        keepAlive: false,
        showLink: false,
        roles:['finance_salary_rule_mod']
      }
    },
    {
      path: "/finance/checkBill",
      name: "checkBill",
      component: () => import("@/views/finance/checkBill/index.vue"),
      meta: {
        title: "对账",
        keepAlive: false,
        showLink: false,
        roles:['finance_bill_payable_settle']
      }
    },
  ]
} as RouteConfigsTable;
