import employeeSvg from "@/assets/svg/employee.svg";
import { getRank } from '../rank'
let rank = getRank('employee')
export default {
  path: "/employee",
  redirect: "/employee/index",
  meta: {
    icon: employeeSvg,
    title: "员工管理",
    rank,
    roles: ['production_router']
  },
  children: [
    {
      path: "/employee/index",
      name: "employee",
      component: () => import("@/views/employee/index.vue"),
      meta: {
        title: "员工管理",
        keepAlive: false,
        roles: ["employee"]
      }
    }
  ]
} as RouteConfigsTable;
