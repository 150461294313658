
import iconShengchan from "../../assets/svg/icon-shengchan.svg";
import { getRank } from '../rank'
let rank = getRank('kunTie')
export default {
  path: "/kun_tie",
  redirect: "/kun_tie/index",
  meta: {
    icon: iconShengchan,
    title: "鲲贴",
    rank,
    roles: ['kun_tie_router']

  },
  children: [

    {
      path: "/kun_tie/index",
      name: "KunTie",
      component: () => import("@/views/kunTie/index.vue"),
      meta: {
        title: "鲲贴",
        keepAlive: false,
        roles: ['kun_tie_router']
      }
    },

  ]
} as RouteConfigsTable;
