import baseSvg from "@/assets/svg/base.svg";
import { getRank } from '../rank'
let rank = getRank('base')
export default {
  path: "/base",
  meta: {
    icon: baseSvg,
    title: "基础资料",
    rank,
    roles: ['production_router']
  },
  children: [
    {
      path: "/base/index",
      name: "base",
      component: () => import("@/views/category/index.vue"),
      meta: {
        title: "款式资料",
        keepAlive: false,
        roles:['style_view']
      }
    },
    {
      path: "/category/categoryManager",
      name: "categoryManager",
      component: () => import("@/views/category/categoryManager.vue"),
      meta: {
        title: "添加/修改商品",
        keepAlive: false,
        showLink: false
      }
    },
    {
      path: "/category/categoryDetail",
      name: "categoryDetail",
      component: () => import("@/views/category/categoryDetail.vue"),
      meta: {
        title: "商品",
        keepAlive: false,
        showLink: false
      }
    },
    {
      path: "/base/brand",
      name: "band",
      component: () => import("@/views/base/brand/index.vue"),
      meta: {
        title: "品牌资料",
        keepAlive: false,
        roles:['brand_view']
      }
    },
    {
      path: "/customer/index",
      name: "customer",
      component: () => import("@/views/customer/index.vue"),
      meta: {
        title: "合作伙伴",
        keepAlive: false,
        roles:['partner_view']
      }
    },
    {
      path: "/cloudPrint/index",
      name: "cloudPrint",
      component: () => import("@/views/cloudPrint/index.vue"),
      meta: {
        title: "云打印机设置",
        keepAlive: false,
        roles:['printer_view']
      }
    },
    {
      path: "/companyInfo/index",
      name: "companyInfo",
      component: () => import("@/views/companyInfo/index.vue"),
      meta: {
        title: "企业信息",
        keepAlive: false,
        roles:['factory_info_mod']
      }
    },
    {
      path: "/account/index",
      name: "Account",
      component: () => import("@/views/base/account/index.vue"),
      meta: {
        title: "资金账户",
        keepAlive: false,
        roles:['finance_account_view']
      }
    },
    {
      path: "/warehouse/index",
      name: "warehouse",
      component: () => import("@/views/CRM/warehouse/index.vue"),
      meta: {
        title: "仓库管理",
        keepAlive: false,
        roles:['warehouse_view']
      }
    },
    {
      path: "/stepTag/index",
      name: "StepTag",
      component: () => import("@/views/base/stepTag/index.vue"),
      meta: {
        title: "工序标签",
        keepAlive: false,
        roles:['step_tag_view']
      }
    },
    
  ]
} as RouteConfigsTable;
