import financeSvg from "../../assets/svg/finance.svg";
import { getRank } from '../rank'
let rank = getRank('log')
export default {
  path: "/logManager",
  redirect: "/logManager/index",
  meta: {
    icon: financeSvg,
    title: "日志管理",
    rank,
    roles: ['production_router']
  },
  children: [
    {
      path: "/logManager/index",
      name: "LogManager",
      component: () => import("@/views/logManager/index.vue"),
      meta: {
        title: "查看日志",
        keepAlive: false,
        roles:['operation_log_view']
      }
    },

  ]
} as RouteConfigsTable;
